<template>
    <!-- <v-container fluid class="ma-0 pa-0 overflow-y-auto" dark style="height: calc(100vh - 48px)"> -->
        <v-container fluid class="ma-0 pa-0" dark>
        <vue-particles
            class="my-auto"
            color="#ffffff"
            linesColor="#ffffff"
            shapeType="circle"
            style="position: fixed; height: 100%; width: 100%;" />

        <v-row no-gutters dark>
            <v-col cols="12">
                <v-card width="100%" color="bg-primary" tile class="text-center" dark>
                    <v-row no-gutters class="d-flex justify-center">
                        <v-btn text class="d-none d-md-flex" @click="$vuetify.goTo('#home')">Home</v-btn>
                        <v-btn text @click="$vuetify.goTo('#pricing')">Pricing</v-btn>
                        <v-btn text @click="$vuetify.goTo('#features')">Features</v-btn>
                        <v-btn v-if="!isLoggedIn" text :to="{ name: 'register' }">Register</v-btn>
                        
                        <v-btn text class="d-none d-md-flex" :to="{ name: 'demo' }">Demo</v-btn>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-card id="home" width="100%" height="100vh" color="bg-primary" tile class="d-flex align-center justify-center" dark>
            <v-container>
                <v-row>
                    <v-col cols="10" md="8" lg="6" class="mx-auto align-center">
                        <v-img src="/img/logo-dark.png" contain />
                    </v-col>
                    <v-col cols="12">
                        <v-col cols="12" md="8" lg="6" class="mx-auto align-center text-center">
                            <v-divider class="my-3" />
                            <div class="text-overline">A Cloud-Based Logistics Platform</div>
                            <div class="text-overline">Online Ordering</div>
                            <div class="text-overline">Inventory</div>
                            <div class="text-overline">Delivery</div>
                            <div class="mt-4">
                                <a @click="$vuetify.goTo('#online-ordering')">
                                    <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-down</v-icon></div>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card id="online-ordering" width="100%" min-height="75vh" color="bg-primary" tile class="d-flex align-center justify-center" style="margin-top: 40vh" dark>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4" class="text-center">
                        <v-icon size="250">mdi-cellphone-text</v-icon>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-col cols="12" md="12" lg="8" class="mx-auto align-center text-center">
                            <div class="text-h4">Online Ordering</div>
                            <v-divider class="my-2" />
                            <v-row no-gutters>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-email</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Communicate better</strong> with automated emailing of orders on approval and completion.
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-weight-lifter</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Lighten your workload</strong> by giving your customers control to manage and track their own orders.
                                    </div>
                                </v-col>
                                <v-col cols="12" class="d-sm-none">
                                    <v-divider class="my-4" />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-calendar-lock</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Control order cycles</strong> through slotted ordering or requiring a minimum lead time.
                                    </div>
                                </v-col>
                            </v-row>
                            
                            <div class="mt-4">
                                <a @click="$vuetify.goTo('#inventory')">
                                    <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-down</v-icon></div>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card id="inventory" width="100%" min-height="75vh" color="bg-primary" tile class="d-flex align-center justify-center" style="margin-top: 40vh" dark>
            <v-container>
                <v-row>
                    <v-col cols="12" class="text-center d-md-none">
                        <v-icon size="250">mdi-cube-outline</v-icon>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-col cols="12" lg="8" class="mx-auto align-center text-center">
                            <div class="text-h4">Inventory</div>
                            <v-divider class="my-2" />
                            <v-row no-gutters>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-clipboard-check</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Keep track</strong> of stock levels, production orders, picking, packing, and product recalls.
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-chef-hat</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Gain mastery</strong> over your production schedule by creating and completing production orders.
                                    </div>
                                </v-col>
                                <v-col cols="12" class="d-sm-none">
                                    <v-divider class="my-4" />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-factory</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Minimize unwelcome surprises</strong> by linking production with procurement of products from other suppliers.
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="mt-4">
                                <a @click="$vuetify.goTo('#delivery')">
                                    <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-down</v-icon></div>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                    <v-col md="4" class="text-center d-none d-md-block">
                        <v-icon size="250">mdi-cube-outline</v-icon>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
 
        <v-card id="delivery" width="100%" min-height="75vh" color="bg-primary" tile class="d-flex align-center justify-center" style="margin-top: 40vh" dark>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4" class="text-center">
                        <v-icon size="250">mdi-truck</v-icon>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-col cols="12" md="12" lg="8" class="mx-auto align-center text-center">
                            <div class="text-h4">Delivery</div>
                            <v-divider class="my-2" />
                            <v-row no-gutters>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-map-marker-path</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Optimize your delivery routes</strong> with simple drag and drop actions.  Monitor driver progress on the road.
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-draw</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Triple-prove delivery</strong> to the customer by recording signature, photo, and GPS coordinates on your phone.
                                    </div>
                                </v-col>
                                <v-col cols="12" class="d-sm-none">
                                    <v-divider class="my-4" />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-chart-multiline</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <strong>Analyze driver efficiencies</strong> with route KPIs and detailed delivery statistics.
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="mt-4">
                                <a @click="$vuetify.goTo('#pricing')">
                                    <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-down</v-icon></div>
                                    <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-down</v-icon></div>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card id="pricing" width="100%" min-height="75vh" color="bg-primary" tile class="d-flex align-center justify-center" style="margin-top: 40vh" dark>
            <v-container>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-col cols="12" lg="10" class="mx-auto align-center text-center">
                            <div class="text-h4">Affordable Pricing</div>
                            <v-divider class="my-2" />
                            <v-row no-gutters>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-emoticon-happy</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        Our aim is to be affordable for small businesses so we've designed each part of the platform to be cheaper than other options out there.
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-scale</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <div><strong>Pricing scales</strong> according to what you use.  It begins with a monthly subscription fee.</div>
                                        <div class="text-subtitle-1"><strong>$35.00 / month</strong></div>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="d-sm-none">
                                    <v-divider class="my-4" />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <div class="ma-4">
                                        <v-icon size="40">mdi-credit-card-outline</v-icon>
                                    </div>
                                    <div class="text-body-1 px-1">
                                        <div>Then just pay for what you use.</div>

                                        <div><strong>+$0.15 / Order</strong></div>
                                        <div><strong>+$0.15 / Courier Order</strong></div>
                                        <div><strong>+$0.15 / Delivery</strong></div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="4" class="align-center">
                        <v-col cols="12" sm="8" md="12" class="mx-auto align-center text-center">
                            <div class="text-h4">Calculator</div>
                            <v-divider class="my-2" />
                            <div class="ma-4">
                                <v-icon size="40">mdi-calculator</v-icon>
                            </div>
                            <BT-Select
                                :canSelectNone="false"
                                class="ma-1"
                                hide-details
                                outlined
                                prefix="Calculate Per"
                                :items="fOptions"
                                v-model="orderFrequency" />

                            <div class="text-body-1 px-1">
                                <v-text-field
                                    hide-details
                                    outlined
                                    solo-inverted
                                    :suffix="`Invoices / ${orderFrequency}`"
                                    type="number"
                                    v-model.number="invoiceCount" />

                                <v-text-field
                                    hide-details
                                    outlined
                                    solo-inverted
                                    :suffix="`Deliveries / ${orderFrequency}`"
                                    type="number"
                                    v-model.number="deliveryCount" />
                                
                                <v-slide-y-transition>
                                    <div v-if="currentSubscription != null && totalMonthlyCost != null">
                                        <p v-if="currentSubscription != null" class="pa-4 pb-0 text-right text-h5">= {{ totalMonthlyCost | toCurrency }} / {{ orderFrequency }}</p>
                                        <p v-if="currentSubscription != null" class="px-4 text-right text-overline">(Approximate)</p>
                                    </div>
                                </v-slide-y-transition>
                            </div>
                        </v-col>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <div>
                            <a @click="$vuetify.goTo('#features')">
                                <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-down</v-icon></div>
                                <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-down</v-icon></div>
                                <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-down</v-icon></div>
                            </a>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card id="features" width="100%" min-height="75vh" color="bg-primary" tile class="d-flex align-center justify-center" style="margin-top: 40vh" dark>
            <v-carousel v-model="featureSlide" hide-delimiters :height="null">
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="text-center">
                                <v-icon size="250">mdi-star-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center align-center">
                                <v-col cols="12" class="text-center align-center">
                                    <div class="text-h4 text-center">Features</div>
                                    <v-divider class="my-2" />
                                    <v-row no-gutters>
                                        <v-spacer />
                                        <v-col cols="12" md="6" lg="5">
                                            <div class="text-body-1 px-1">
                                                We've invested a lot of effort into building good tools - tools that help manage sales, stock levels, procurement, manufacturing, routing, picking, packing, and delivering.  There's a tool for pretty much every task.
                                            </div>
                                        </v-col>
                                        <v-spacer />
                                    </v-row>
                                    <div class="mt-4 text-center">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Sales Tool</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">Manage customer orders</div>
                                    <div class="text-subtitle-1">Create standing orders</div>
                                    <div class="text-subtitle-1">Night mode keeps it easy on the eyes</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy>
                                    <v-img class="mx-auto" height="100%" src="https://blitzittechimages.blob.core.windows.net/blitzit/sales.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Manufacturing Tools</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">Create & complete production orders</div>
                                    <div class="text-subtitle-1">Create & convert batches</div>
                                    <div class="text-subtitle-1">Check live progress remotely</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy>
                                    <v-img class="mx-auto" height="100%" src="https://blitzittechimages.blob.core.windows.net/blitzit/production.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Customer Experience</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">Give customers control over their ordering</div>
                                    <div class="text-subtitle-1">Follow progress of an order or delivery</div>
                                    <div class="text-subtitle-1">Access past invoices and proof of delivery</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy>
                                    <v-img class="mx-auto" width="300" src="https://blitzittechimages.blob.core.windows.net/blitzit/customer-dashboard.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Delivery Tools</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">Complete deliveries on your phone</div>
                                    <div class="text-subtitle-1">Temperature recording</div>
                                    <div class="text-subtitle-1">Photo, signature, and GPS proof</div>
                                    <div class="text-subtitle-1">Odometre readings</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy min-height="200">
                                    <v-img class="mx-auto" width="300" src="https://blitzittechimages.blob.core.windows.net/blitzit/driving.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Route Optimization</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">Visualize routes on a map</div>
                                    <div class="text-subtitle-1">Drag & drop between journeys</div>
                                    <div class="text-subtitle-1">Easily create new journeys</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy min-height="200">
                                    <v-img class="mx-auto" height="100%" src="https://blitzittechimages.blob.core.windows.net/blitzit/journey-optimizing.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Pick & Pack Tools</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">Pack individual orders</div>
                                    <div class="text-subtitle-1">Pick for a journey</div>
                                    <div class="text-subtitle-1">Print delivery slips</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy min-height="150">
                                    <v-img class="mx-auto" height="100%" src="https://blitzittechimages.blob.core.windows.net/blitzit/picking.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
                <v-carousel-item>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6" class="d-flex align-center justify-center">
                                <div class="text-center">
                                    <div class="text-h4">Stock Levels</div>
                                    <v-divider class="my-2" />
                                    <div class="text-subtitle-1">In stock monitoring</div>
                                    <div class="text-subtitle-1">Hold & release</div>
                                    <div class="text-subtitle-1">Record wastage</div>
                                    <div class="mt-4">
                                        <v-btn text @click="featureSlide += 1">
                                            <div><v-icon class="down-arrow down-arrow-one">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-two">mdi-chevron-right</v-icon></div>
                                            <div><v-icon class="down-arrow down-arrow-three">mdi-chevron-right</v-icon></div>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="text-center">
                                <v-lazy min-height="200">
                                    <v-img class="mx-auto" height="100%" src="https://blitzittechimages.blob.core.windows.net/blitzit/stock-levels.gif" />
                                </v-lazy>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-carousel-item>
            </v-carousel>
        </v-card>

        <v-card id="contact" width="100%" min-height="30vh" color="bg-primary" tile class="d-flex align-center justify-center" style="margin-top: 40vh" dark>
            <v-container>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h1 class="my-4">Sign Me Up!</h1>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <v-btn
                                    class="mx-1 bg-primary lighten-1"
                                    large
                                    href="mailto:blitzittech@gmail.com?subject=I'm Interested!&body=Hey Team, I'd like to make contact and maybe even create an account on BlitzIt Web.  Can you get back to me, please?">Send Email</v-btn>
                                <v-btn
                                    class="mx-1 bg-primary lighten-1"
                                    large
                                    :to="{ name: 'register' }">
                                    <v-icon left>mdi-account-circle</v-icon>Register
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: 'Home-Visit',
        components: {
            
        },
        data: function () {
            return {
                currentSubscription: 'Supplier',
                
                deliveryCount: 0,
                courierOrderCount: 0,
                invoiceCount: 0,
                featureSlide: 0,
                pricingSlide: 0,

                orderFrequency: 'Month', //'Day', 'Month', 'Year'
                fOptions: ['Day', 'Week', 'Month', 'Year'],
                sOptions: ['Customer', 'Supplier', 'Courier'],
                
                baseFees: [7, 35, 35],
                invoiceCosts: [0, 0.15, 0.15],
                deliveryCosts: [0, 0.15, 0.15],
                courierOrderCosts: [0, 0.15, 0.15],
            }
        },
        mounted() {
            if (this.$route.hash != null && this.$route.hash.length > 0) {
                this.$vuetify.goTo(this.$route.hash);
            }

            this.$BlitzIt.style.setTemporaryColor(this.$vuetify, this.$BlitzIt.style.root.cosmetics.dark['bg-primary']);
        },
        destroyed() {
            this.$BlitzIt.style.undoTemporaryColor(this.$vuetify);
        },
        computed: {
            // totalMonthlyCost() {
            //     var sInd = this.sOptions.findIndex(x => x == this.currentSubscription);

            //     if (sInd < 0 || this.orderFrequency == null) {
            //         return null;
            //     }

            //     var subCost = this.baseFees[sInd];
            //     var yearlyMultiple = 0;
            //     if (this.orderFrequency == 'Day') {
            //         yearlyMultiple = 365;
            //     }
            //     else if (this.orderFrequency == 'Week') {
            //         yearlyMultiple = 52;
            //     }
            //     else if (this.orderFrequency == 'Month') {
            //         yearlyMultiple = 12;
            //     }
            //     else if (this.orderFrequency == 'Year') {
            //         yearlyMultiple = 1;
            //     }

            //     var dCost = this.deliveryCosts[sInd] * this.deliveryCount * yearlyMultiple;
            //     var iCost = this.invoiceCosts[sInd] * this.invoiceCount * yearlyMultiple;
            //     var cCost = dCost; // this.courierOrderCosts[sInd] * this.courierOrderCount * yearlyMultiple;

            //     var e = Math.round(subCost + ((dCost + iCost + cCost) / 12));

            //     if (e == 0) {
            //         return null;
            //     }

            //     return e;
            // },
            totalMonthlyCost() {
                var sInd = this.sOptions.findIndex(x => x == this.currentSubscription);

                if (sInd < 0 || this.orderFrequency == null) {
                    return null;
                }

                //calculate yearly, the divide
                var subCost = this.baseFees[sInd] * 12;
                var dCost = this.deliveryCosts[sInd] * this.deliveryCount;
                var cCost = this.courierOrderCosts[sInd] * this.deliveryCount;
                var oCost = this.invoiceCosts[sInd] * this.invoiceCount;

                var rate = 0
                
                if (this.orderFrequency == 'Day') {
                    rate = (subCost / 365) + dCost + cCost + oCost;
                }
                else if (this.orderFrequency == 'Week') {
                    rate = (subCost / 52) + dCost + cCost + oCost;
                }
                else if (this.orderFrequency == 'Month') {
                    rate = (subCost / 12) + dCost + cCost + oCost
                }
                else if (this.orderFrequency == 'Year') {
                    rate = (subCost / 1) + dCost + cCost + oCost
                }

                rate = Math.round(rate);

                if (rate == 0) {
                    return null;
                }

                return rate;
            },
            isLoggedIn() {
                return this.$BlitzIt.auth.session?.isLoggedIn;
            },
            isMobile() {
                return this.$vuetify.breakpoint.mobile;
            }
        },
        methods: {
            scrollIntoView(id) {
                this.$vuetify.goTo(id);
            }
        }
    }
</script>

<style scoped>

.down-arrow {
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}

.down-arrow-one {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.down-arrow-two {
  /* top: 16px; */
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}

.down-arrow-three {
  /* top: 32px; */
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>